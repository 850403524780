
.team-outer {
    padding: 30px 80px 60px 80px;
}

.team-section, .ceo-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.member {
    width: 25%;
    padding: 25px;
}
.member-img {
    width: 240;
    height: 240px;
    border-radius: 50%;
}
.member-name {
    padding: 5px 0;
    font-size: 25px;
    font-weight: 600;
}
.member-exp {
    padding: 8px 3px;
    font-size: 22px;
    background-color: gray;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}


@media screen and (max-width: 1150px) {
    .team-section, .ceo-section {
        display: block;
    }
    .member {
        width: 100%;
    }
    .team-outer {
        padding: 30px 30px 60px 30px;
    }
}