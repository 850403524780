
.service-list {
    padding: 30px 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.service-container {
    width: 25%;
    padding: 20px 30px;
}

.service-img {
    width: 100%;
    height: 265px;
}

.service-title {
    padding: 2%;
    font-size: 30px;
    font-weight: 700;
}

.service-description {
    padding: 2%;
    text-align: justify;
}


@media screen and (max-width: 1150px) {
    .service-list {
        display: block;
        padding: 30px 20px;
    }
    .service-container {
        width: 100%;
    }
    .service-img {
        height: auto;    
    }
}