
.third-container-main {
    width: 100%;
    padding: 4% 6%;
    background-color: rgb(45,10,49);
    color: white;
    text-align: center;
}

.third-container-title {
    font-size: 300%;
    font-weight: 700;
    padding-bottom: 4%;
}

.third-container-inner {
    display: inline-flex;
}

.third-container-block {
    padding: 2%;
    color: white;
    width: 25%;
}

.block-img {
    width: 100%;
    height: 255px;
}

.block-text {
    height: 100px;
    padding: 2%;
}

.block-text a {
    font-size: 30px;
    font-weight: 500;
    text-decoration: none;
    color: white;
}

.block-text a:hover {
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 1150px) {
    .third-container-inner {
        display: block;
    }
    .third-container-block {
        width: 100%;
        padding-top: 10%;
    }
    .block-img {
        height: auto;
    }
}