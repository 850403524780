.service-header {
    width: 100%;
    background-color: rgb(45,10,49);
    padding: 80px 0;
    color: white;
    font-size: 40px;
    font-weight: 700;
}

.intro {
    padding: 80px 0 60px 0;
}
.intro-header {
    font-weight: 650;
}
.intro-body {
    margin: 20px 0;
    font-size: 17px;
}

/* for contact.js */
.section-title {
    max-width : 600px;
    padding: 50px;
}
