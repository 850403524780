
.fourth-container-main {
    width: 100%;
    padding: 4% 6%;
}

.fourth-container-inner {
    display: grid;
    grid-template-columns: 10fr 8fr;
}

.fourth-container-img {
    padding: 0% 5%;
}

.fourth-container-text {
    text-align: left;
    padding: 4% 0 0 3%;
}

.fourth-container-text h1 {
    font-size: 300%;
    font-weight: 600;
    padding: 2% 0%;
}
.fourth-container-text p {
    font-size: 18px;
    padding: 1% 0% 2% 0%;
}

.exp-peo-text {
    font-size: 23px;
    font-weight: 500;
}
.exp-peo-number {
    font-size: 40px;
    font-weight: 500;
    margin: 20px;
}

@media screen and (max-width: 1150px) {
    .fourth-container-inner {
        display: block;
        grid-template-columns: none;
    }
}