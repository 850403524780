
.call-mail {
    display: flex;
    justify-content: center;
}

.call-section, .mail-section {
    display: flex;
    padding: 10px 30px;
    text-align: left;
}

.icon-box {
    width: 60px;
}

.form {
    padding: 50px 0;
    text-align: center;
}
.form input, textarea {
    background-color: #EEF9FF;
    Border: 0;
    margin: 10px;
    padding: 15px;
    border-radius: 3px;
}
.form input:focus, textarea:focus {
    outline: 2px solid skyblue;
}
.name, .email {
    width: 400px;
}
.mobile, .message, .send-btn {
    width: 820px;
}


@media screen and (max-width: 1150px) {
    .call-mail {
        display: block;
    }
    .call-mail h4{
        font-size: 18px;
    }
    .call-section, .mail-section {
        padding: 10px;
    }

    .name, .email {
        width: 94%;
    }
    .mobile, .message, .send-btn {
        width: 94%;
    }
}