.footer {
    background-color: white;
    padding: 5% 0% 3% 0%;
}

.container {
    text-align: left;
    display: flex;
}
.container ul {
    list-style-type: none;
    padding: 10px 0; 
}
.container li {
    list-style: none;
    margin: 3% 0 2% 0;
}
.container h1, h4 {
    margin-bottom: 5%;
}

.social-links {
    padding: 5% 0;
}
.social-links a {
    margin-right: 20px;
}

.footer-title {
    width: 32%;
}

.footer-links {
    width: 10%;
}

.footer-services {
    width: 10%;
}

.footer-office {
    width: 30%;
}

.footer-bottom {
    padding-top: 2%;
}


@media screen and (max-width: 1150px) {
    .container {
        display: block;
    }
    .footer-title,
    .footer-links,
    .footer-services,
    .footer-office {
        width: 100%;
    }
}