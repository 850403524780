
.fifth-container-main {
    width: 100%;
    padding: 130px 6%;
    background-image: url("../../assets/images/FifthBlockImage.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;
    color: white;
}

.fifth-container-inner h1 {
    font-size: 45px;
    font-weight: 650;
    margin-bottom: 30px;
}

.fifth-container-inner p {
    font-size: 20px;
    margin-bottom: 25px;
}

.fifth-container-inner a {
    padding: 10px 50px;
    background: none;
    border: 1px solid #F067FF;
    color: #F067FF;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 20px;
}
.fifth-container-inner a:hover {
    text-decoration: none;
    color: white;
}
