.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.nav-menu {
    width: 80%;
}

.nav-list {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    row-gap: 2.5rem;
}

.nav-brand {
    color : black;
    font-weight: bold;
}

.nav-item {
    color : black;
    font-weight: bold;
    margin: 0% 2% 0% 2%;
}
.nav-item:hover {
    text-decoration: none;
    color : black;
}

.nav-toggle,
.nav-close {
 font-size: 1.5rem;
 color: var(--title-color);
 cursor: pointer;
}
.nav-close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}


@media screen and (max-width: 1150px) {
    .nav-menu {
      position: fixed;
      top: 0;
      right: -100%;
      background-color: rgb(252,252,252);
      width: 85%;
      height: 100%;
      padding: 6rem 3rem 0 2rem;
      transition: right 0.4s;
    }
    .nav-item {
        text-align: left;
        border-bottom: 2px solid rgb(200,200,200);
        padding: 0 0 2px 7px;
    }
}

.show-menu {
    right: 0;
}

@media screen and (min-width: 1150px) {
    .nav {
      height: calc(var(--header-height) + 1rem);
    }
    .nav-toggle,
    .nav-close {
      display: none;
    }
    .nav-list {
      flex-direction: row;
      column-gap: 2.5rem;
    }
}

.false-space {
    height: 75px;
}



