
.second-container-main {
    width: 100%;
    padding: 5% 6%;
}

.second-container-inner {
    display: grid;
    grid-template-columns: 8fr 10fr;
}

.second-container-img {
    padding: 0% 5%;
}

.second-container-text {
    text-align: left;
    padding: 9% 0 0 3%;
}

.second-container-text h1 {
    font-size: 300%;
    font-weight: 600;
    padding: 2% 0%;
}
.second-container-text p {
    font-size: 18px;
    padding: 1% 0% 2% 0%;
}

@media screen and (max-width: 1150px) {
    .second-container-inner {
        display: block;
        grid-template-columns: none;
    }
}