
.first-container-main {
    width: 100%;
    background-color: rgb(45,10,49);
    padding: 6%;
}

.first-container-inner {
    display: grid;
    grid-template-columns: 10fr 8fr;
}

.first-container-text {
    text-align: left;
    padding-top: 7%;
}

.first-container-text span {
    color: #9F78FF;
    font-size: 18px;
}
.first-container-text h1 {
    color: #fff;
    font-size: 300%;
    font-weight: 600;
    padding: 2% 0%;
}
.first-container-text p {
    color: #A5A7C5;
    font-size: 20px;
    padding: 1% 0% 2% 0%;
}


@media screen and (max-width: 1150px) {
    .first-container-img {
        position: absolute;
        display: none;
    }
    .first-container-inner {
        display: block;
        grid-template-columns: none;
    }
}